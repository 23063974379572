import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { BlockCartEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import Wrapper from "~/components/Wrapper"
import { useCart } from "~/hooks/useCart"
import CartUpload from "../cart/CartUpload"
import LineItemPreviewWrapper from "../cart/line-item/LineItemPreviewWrapper"
import NextButton from "../scroll-wrappers/NextButton"

interface Props {
  config: BlockCartEntityHydrated
}

const CartBlock: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const { isDropzoneEnabled, customDropzoneImage } = config
  const { cart } = useCart()
  const lineItemIds = cart.lineItemIds
  const deviceType = useBreakpointValue({ base: "mobile", md: "desktop" })

  const showDropZoneAtTheTop =
    Boolean(isDropzoneEnabled) && deviceType !== "mobile"
  const showDropZoneAtTheBottom =
    Boolean(isDropzoneEnabled) && deviceType === "mobile"

  return (
    <Wrapper id="p-cart" py={16} hasSeparator={true}>
      <Heading as="h3" mb={3} fontSize="2xl" textAlign="center">
        {t("components.blocks.CartBlock.Heading")}
      </Heading>
      <Text mb={10} textAlign="center">
        {t("components.blocks.CartBlock.Subheading")}
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacingX="40px"
        spacingY="40px"
        data-testid={`image-preview-grid`}
      >
        {showDropZoneAtTheTop && (
          <Box alignItems="flex-start" id="drop-zone-desktop">
            <CartUpload customDropzoneImage={customDropzoneImage} />
          </Box>
        )}
        {lineItemIds.map(id => (
          <LineItemPreviewWrapper lineItemId={id} key={id} />
        ))}
        {showDropZoneAtTheBottom && (
          <Box alignItems="flex-start" id="drop-zone-mobile">
            <CartUpload customDropzoneImage={customDropzoneImage} />
          </Box>
        )}
      </SimpleGrid>
      <NextButton currentBlock="cart" />
    </Wrapper>
  )
}

export default React.memo(CartBlock)
