import { Flex, FlexProps, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props extends FlexProps {
  pageHeight: number
  pageWidth: number
  baseHeight?: number
  isMobile?: boolean
}

const BlankPage: React.FC<Props> = ({
  pageHeight,
  pageWidth,
  baseHeight = 80,
  isMobile = false,
  ...props
}) => {
  const { t } = useTranslation()
  const ratio = pageWidth / pageHeight
  const boxWidth = baseHeight * ratio

  return (
    <Flex
      h="full"
      w={isMobile ? "full" : `${boxWidth}px`}
      justify="center"
      align="center"
      {...props}
    >
      <Text px={1} fontSize={{ base: "xs", sm: "10px" }} textAlign="center">
        {t(
          "coponents.editors.common.pagination.PhotoBookPageList.BlankPage.Text"
        )}
      </Text>
    </Flex>
  )
}

export default BlankPage
