import { EntityId } from "@jackfruit/common"
import { useApplicationState } from "./useApplicationState"
import { useLineItem } from "./useLineItem"
import { useProductImages } from "./useProductImages"

/**
 * @param id - The line item ID corresponding to the photo book.
 */

const defaultLocale = "en-US"

export const usePhotoBook = (id: EntityId) => {
  const { language } = useApplicationState()
  const { lineItem, product } = useLineItem(id)
  const { productImages } = useProductImages(lineItem.productId)

  const isPhotoBook = product.categoryName === "photo-book"

  const photoBookFrame = productImages.find(
    image => image.metaData?.editorFrame !== undefined
  )

  const photoBookCoverDefaultThumbnail = productImages.find(
    image => image.metaData?.photoBook?.staticCoverThumbnail
  )

  const photoBookCoverThumbnail = productImages.find(
    image =>
      image.metaData?.photoBook?.staticCoverThumbnail &&
      (image.metaData?.photoBook?.locale ?? defaultLocale) === language
  )

  const photoBookCoverDefaultPreview = productImages.find(
    image => image.metaData?.photoBook?.staticCoverPreview
  )

  const photoBookCoverPreview = productImages.find(
    image =>
      image.metaData?.photoBook?.staticCoverPreview &&
      (image.metaData?.photoBook?.locale ?? defaultLocale) === language
  )

  const hasCoverDefault = Boolean(
    photoBookCoverDefaultThumbnail && photoBookCoverDefaultPreview
  )

  const hasCoverByLocale = Boolean(
    photoBookCoverThumbnail && photoBookCoverPreview
  )

  const hasCover = hasCoverByLocale || hasCoverDefault

  return {
    isPhotoBook,
    hasCover,
    photoBookFrame,
    photoBookCoverThumbnail:
      photoBookCoverThumbnail || photoBookCoverDefaultThumbnail,
    photoBookCoverPreview:
      photoBookCoverPreview || photoBookCoverDefaultPreview,
  }
}
