import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useMemo } from "react"
import { useImageRegionsWithShallowEqual } from "./useImageRegions"
import { useLineItem } from "./useLineItem"
import { useProductPagesWithShallowEqual } from "./useProductPages"
import { useUploadEntitiesWithShallowEqual } from "./useUploadEntities"

export const useAllPageUploadsAreReadyForLineItem = (lineItemId: EntityId) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { productPages } = useProductPagesWithShallowEqual(productPageIds)

  const allImageRegionIds = useMemo(() => {
    return flatten(productPages.map(productPage => productPage.imageRegionIds))
  }, [productPages])

  const { imageRegions } = useImageRegionsWithShallowEqual(allImageRegionIds)

  const allUploadIds = useMemo(() => {
    return imageRegions
      .filter(imageRegion => imageRegion.uploadId)
      .map(imageRegion => imageRegion.uploadId as EntityId)
  }, [imageRegions])

  const { uploads } = useUploadEntitiesWithShallowEqual(allUploadIds)

  // TODO
  // null check required until #1812 is deployed
  return uploads.every(upload => upload && upload.status === "ready")
}
