import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useMemo, useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useProduct } from "~/hooks/useProduct"
import { useUpload } from "~/hooks/useUpload"
import { getDefaultImageTransformation } from "~/services/Utils"
import {
  imageTransformationZoomStateFamily,
  editorCurrentProductId,
} from "./atoms"

interface Props {
  imageRegionId: EntityId
  imageTransformId: EntityId
  disabled: boolean
}

const EditorImageZoomSliderTopbar: React.FC<Props> = ({
  imageRegionId,
  imageTransformId,
  disabled,
}) => {
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)

  const regionSize = {
    width: imageRegion?.window.width || 0,
    height: imageRegion?.window.height || 0,
  }

  const imageSize = {
    width: image?.width || 0,
    height: image?.height || 0,
  }

  const [zoom, setZoom] = useRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)

  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )
  const imageFit = product?.metaData?.web?.editor?.imageFit

  const zoomSetting = getDefaultImageTransformation(
    regionSize,
    imageSize,
    0,
    imageFit,
    isClampingEnabled
  )

  const zoomStep = zoomSetting.zoomStep * zoomSetting.zoom
  const maxZoom = zoomSetting.maxZoom
  const minZoom = zoomSetting.minZoom

  return (
    <Flex w="full" verticalAlign="center">
      <Slider
        width={40}
        isDisabled={disabled}
        defaultValue={1}
        value={zoom}
        min={minZoom}
        max={maxZoom}
        step={zoomStep}
        onChange={setZoom}
        size="lg"
      >
        <SliderTrack boxSize={2} borderRadius={4}>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderWidth={3} borderColor="primary.800" boxSize={6} />
      </Slider>
    </Flex>
  )
}

export default EditorImageZoomSliderTopbar
