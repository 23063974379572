import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useCart } from "~/hooks/useCart"
import { useImageRegions } from "~/hooks/useImageRegions"
import { useLineItem } from "~/hooks/useLineItem"
import { usePrintService } from "~/hooks/usePrintService"
import { useProduct } from "~/hooks/useProduct"
import { useProductPages } from "~/hooks/useProductPages"

/**
 * Collect data for GA4 abandonment rate tracking
 *
 * @param  id - lineItemId
 * @returns filledProductPages - productPages with at least 1 uploaded image
 * @returns filledProductPagesCount - count of filledProductPages
 */

export const useEditorStats = (id: EntityId) => {
  const {
    lineItem: { productId, productPageIds },
  } = useLineItem(id)

  const {
    cart: { fulfillment, printServiceId },
  } = useCart()

  const {
    printService: { displayName },
  } = usePrintService(printServiceId)

  const {
    product: { name: productName, categoryName },
  } = useProduct(productId)

  const { productPages } = useProductPages(productPageIds)

  const imageRegionIds = flatten(productPages.map(page => page.imageRegionIds))

  const { imageRegions } = useImageRegions(imageRegionIds)

  const filledProductPages = productPages.filter(page =>
    imageRegions.some(
      imageRegion =>
        page.imageRegionIds.includes(imageRegion.id) && imageRegion.uploadId
    )
  )

  return {
    productName,
    fulfillment,
    productCategoryName: categoryName,
    printServiceName: displayName,
    filledProductPages,
    filledProductPagesCount: filledProductPages.length,
  }
}
