import { Button, Flex, Icon, Text } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { usePageEditorPagination } from "./hooks/usePageEditorPagination"

const pagerIconSize = 10

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const PageEditorPagerMobileLandscape: React.FC<Props> = ({
  lineItemId,
  children,
}) => {
  const { hasPrevious, hasNext, handlePrevious, handleNext, currentPageLabel } =
    usePageEditorPagination(lineItemId)

  return (
    <>
      <Flex
        aria-label="PageEditorPagerMobileLandscape"
        width="full"
        alignItems="center"
        direction="row"
        height="full"
      >
        <Flex height="full" flex={0.12}>
          <Button
            id="previous-page-button"
            width="full"
            isDisabled={!hasPrevious}
            variant="link"
            leftIcon={<Icon as={FiChevronLeft} boxSize={pagerIconSize} />}
            onClick={handlePrevious}
          />
        </Flex>
        <Flex
          flex={1}
          height="full"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {children}
        </Flex>
        <Flex height="full" flex={0.12} justifyContent="flex-end">
          <Button
            id="next-page-button"
            width="full"
            isDisabled={!hasNext}
            variant="link"
            rightIcon={<Icon as={FiChevronRight} boxSize={pagerIconSize} />}
            onClick={handleNext}
          />
        </Flex>
      </Flex>
      <Flex pt={3} justifyContent="space-between" alignItems="center">
        <Text>{currentPageLabel}</Text>
      </Flex>
    </>
  )
}

export default PageEditorPagerMobileLandscape
