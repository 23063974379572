import { Box, Center, CircularProgress, Flex, Text } from "@chakra-ui/react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { EntityId } from "@jackfruit/common"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useProductPage } from "~/hooks/useProductPage"
import { useTextRegions } from "~/hooks/useTextRegions"
import { useUpload } from "~/hooks/useUpload"
import { sanitizeTemplate } from "~/services/SvgUtils"
import ImageRegionPreview from "../ImageRegionPreview"
import { boxHeight } from "../pagination/PageNavigationButton"
import { TextRegionPreview } from "../TextRegionPreview"

interface Props {
  productPageId: EntityId
}

const StaticPhotoBookPage: React.FC<Props> = ({ productPageId }) => {
  const { t } = useTranslation()
  const { productPage } = useProductPage(productPageId)
  const imageRegionIds = productPage!.imageRegionIds ?? []
  const textRegionIds = productPage!.textRegionIds ?? []
  const { imageRegion } = useImageRegion(imageRegionIds[0])
  const { textRegions } = useTextRegions(textRegionIds)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId ?? 0)

  const sanitizedSvg = useMemo(() => {
    return sanitizeTemplate(productPage!.svg || "")
  }, [productPage])

  const ratio = productPage!.width / productPage!.height
  const boxWidth = boxHeight * ratio

  // show a loader only if an image is present, but localUrl is not yet set
  const canShowImage = image && image.localUrl
  const isImageLoading = image && !canShowImage
  const isImageUploaded = Boolean(imageRegion?.uploadId)
  const disableDnd = !isImageUploaded || isImageLoading

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: productPageId,
      disabled: disableDnd,
    })

  if (isImageLoading) {
    return (
      <Center w={`${boxWidth}px`} height={`${boxHeight}px`} mx={1}>
        <CircularProgress isIndeterminate={true} color="primary.400" />
      </Center>
    )
  }

  return (
    <Flex
      aria-label="static-page"
      ref={setNodeRef}
      position="relative"
      zIndex={10}
      {...listeners}
      {...attributes}
      transform={CSS.Translate.toString(transform)}
      transition={transition || undefined}
      cursor={disableDnd ? "pointer" : "grab"}
      mx={1}
      justify="center"
      align="center"
    >
      {!isImageUploaded && (
        <Text px={2} position="absolute" fontSize="10px" textAlign="center">
          {t("components.editors.common.layers.StaticPhotoBookPage.TapToAdd")}
        </Text>
      )}
      <Box w={`${boxWidth}px`} h={`${boxHeight}px`}>
        <svg viewBox={`0 0 ${productPage?.width} ${productPage?.height}`}>
          {!isImageUploaded && (
            <rect
              x={0}
              y={0}
              width={productPage?.width}
              height={productPage?.height}
              fill="#D9D9D9"
            />
          )}
          {imageRegionIds.map(imageRegionId => {
            return (
              <ImageRegionPreview
                key={imageRegionId}
                imageRegionId={imageRegionId}
                isRealTime={true}
              />
            )
          })}
          <g
            width="100%"
            height="100%"
            style={{ pointerEvents: "none" }}
            dangerouslySetInnerHTML={{
              __html: sanitizedSvg,
            }}
          ></g>
          {textRegions.map(textRegion => {
            return (
              <TextRegionPreview
                key={textRegion.id}
                textRegionId={textRegion.id}
              />
            )
          })}
        </svg>
      </Box>
    </Flex>
  )
}

export default StaticPhotoBookPage
