import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRecoilValue } from "recoil"
import { editorCurrentProductId } from "~/components/editors/common/atoms"
import { ViewBox } from "~/interfaces/editors/viewbox"
import { imageRegions, imageRegionsSelectors } from "~/redux/state/imageRegions"
import { RootState } from "~/redux/store"
import { useProcessActions } from "./useProcessActions"
import { useProduct } from "./useProduct"

export const useImageRegion = (id: EntityId) => {
  const imageRegion = useSelector((state: RootState) =>
    imageRegionsSelectors.selectById(state, id)
  )

  return {
    imageRegion,
  }
}

export const useImageRegionActions = () => {
  const dispatch = useDispatch()
  const process = useProcessActions()
  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)
  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )
  const imageFit = product?.metaData?.web?.editor?.imageFit

  return {
    updateWindow: useCallback(
      (id: EntityId, window: ViewBox) => {
        dispatch(
          imageRegions.actions.updateOne({
            id,
            changes: {
              window,
            },
          })
        )
      },
      [dispatch]
    ),

    updateImage: useCallback(
      (id: EntityId, file: File) => {
        process.updateImageRegionImage({
          imageRegionId: id,
          file,
          imageFit,
          isClampingEnabled,
        })
      },
      [imageFit, isClampingEnabled, process]
    ),
  }
}
