import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  maxImages: number
  minImages: number
  isOpen: boolean
  onClose: () => void
  openFileDialog: () => void
}

const PromptModal: React.FC<Props> = ({
  maxImages,
  minImages,
  onClose,
  isOpen,
  openFileDialog,
}) => {
  const { t } = useTranslation()

  const openPhotoUploadDialog = () => {
    openFileDialog()
    onClose()
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        py={4}
        px={{ base: 3, md: 12 }}
        mx={{ base: 4, md: 0 }}
        bottom={{ base: 24, md: 0 }}
      >
        <Image
          src="images/PhotoUpload.png"
          alt="photo upload logo"
          w="auto"
          h={{ base: 24, md: 28 }}
          mx="auto"
          mt={4}
        />

        <ModalHeader
          px={{ base: 3, md: 4 }}
          pt={{ base: 0, md: 2 }}
          pb={2}
          textAlign="center"
        >
          {t("components.editors.common.PromptModal.uploadConfirmation.Title")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box textAlign="center">
            {t(
              "components.editors.common.PromptModal.uploadConfirmation.Description",
              {
                minImages,
                maxImages,
              }
            )}
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" py={{ base: 2, md: 4 }}>
          <Button
            w={40}
            data-testid="start-upload-button"
            id="start-upload-button"
            colorScheme="primary"
            autoFocus={true}
            onClick={openPhotoUploadDialog}
          >
            {t(
              "components.editors.common.PromptModal.uploadConfirmation.ConfirmLabel"
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PromptModal
