import { Box, Button, Fade, Flex, Icon, Slide, Text } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiX } from "react-icons/fi"

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const NavigationDrawer: React.FC<Props> = ({ isOpen, onClose, children }) => {
  const { t } = useTranslation()

  return (
    <>
      <Fade in={isOpen}>
        <Box
          id="close-drawer-overlay"
          onClick={onClose}
          pointerEvents={isOpen ? "auto" : "none"}
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          bg="blackAlpha.300"
          backdropFilter="auto"
          backdropBlur="8px"
        />
      </Fade>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <Box boxShadow="lg" bg="white" borderTopRadius="md" w="full">
          <Flex p={3} justifyContent="center" borderTopRadius="md">
            <Text>
              {t(
                "components.editors.common.pagination.NavigationDrawer.AllPages"
              )}
            </Text>
            <Button
              id="close-drawer-button"
              variant="unstyled"
              position="absolute"
              top={3}
              right={3}
              onClick={onClose}
            >
              <Icon as={FiX} boxSize={8} color="gray.500" />
            </Button>
          </Flex>
          <Box overflowY="scroll" h="65vh">
            {children}
          </Box>
        </Box>
      </Slide>
    </>
  )
}

export default NavigationDrawer
