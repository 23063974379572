import {
  Box,
  Button,
  Flex,
  GridItem,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react"
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable"
import { EntityId } from "@jackfruit/common"
import { first, last } from "lodash"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { GiClick } from "react-icons/gi"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { getOrientationDimensions } from "~/services/Utils"
import PhotoBookPage from "../layers/PhotoBookPage"
import BlankPage from "../photo-book/BlankPage"
import Cover from "../photo-book/Cover"
import { currentSelectedPageIdFamily } from "./atoms"
import PhotoBookPageWrapper from "./PhotoBookPageWrapper"

interface Props {
  lineItemId: EntityId
  pageIds: EntityId[]
  handleDragEnd: (event: DragEndEvent) => void
  onInsertPages: () => void
}

const PhotoBookMobilePageList: React.FC<Props> = ({
  lineItemId,
  pageIds,
  handleDragEnd,
  onInsertPages,
}) => {
  const { t } = useTranslation()
  const {
    product,
    lineItem: { orientation },
  } = useLineItem(lineItemId)

  const { maxImages } = product
  const { hasCover, photoBookCoverThumbnail } = usePhotoBook(lineItemId)

  const { setNodeRef } = useDroppable({
    id: `view-all-${lineItemId}`,
  })

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 200,
      tolerance: 5,
    },
  })
  const mouseSensor = useSensor(MouseSensor)

  const sensors = useSensors(touchSensor, mouseSensor)

  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setCurrentSelectedPageId(event.active.id)
    },
    [setCurrentSelectedPageId]
  )

  const { width: coverWidth, height: coverHeight } =
    photoBookCoverThumbnail?.metaData.photoBook!

  const { width, height } = getOrientationDimensions(
    product.pixelWidth,
    product.pixelHeight,
    orientation ?? product.metaData?.orientationOverride ?? "landscape"
  )

  const firstPageId = first(pageIds)
  const lastPageId = last(pageIds)
  const middlePageIds = pageIds.filter(
    id => ![firstPageId, lastPageId].includes(id)
  )

  const currentPageCount = pageIds.length

  const canAddMorePages = currentPageCount < maxImages

  if (pageIds.length === 0) {
    return null
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={rectSortingStrategy} items={[...pageIds]}>
        <SimpleGrid
          ref={setNodeRef}
          columns={2}
          rowGap={8}
          my={4}
          px={4}
          mx="auto"
          maxW={96}
        >
          <Flex
            mx="auto"
            maxW={32}
            pr={3}
            gap={1}
            direction="column"
            align="center"
            justify="center"
          >
            <Icon as={GiClick} />
            <Text fontSize="xs" textAlign="center">
              {t(
                "components.editors.common.pagination.PhotoBookPageList.DragNDrop"
              )}
            </Text>
          </Flex>

          {hasCover && (
            <PhotoBookPageWrapper
              pageName={t(
                `components.editors.common.pagination.PhotoBookPageList.Cover`
              )}
            >
              <Flex
                w="full"
                p={1}
                boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
                borderRadius="md"
                align="center"
                flexDirection="column"
              >
                <Cover
                  height={coverHeight}
                  width={coverWidth}
                  image={photoBookCoverThumbnail!.thumbnail}
                  isMobile
                />
              </Flex>
            </PhotoBookPageWrapper>
          )}

          <Flex direction="column" align="center">
            <Box
              w="full"
              h="full"
              mb={1}
              borderWidth={2}
              borderColor="transparent"
              borderLeftRadius="md"
              boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
            >
              <BlankPage pageHeight={height} pageWidth={width} isMobile />
            </Box>
            <Text>
              {t(
                "components.editors.common.pagination.PhotoBookMobilePageList.BlankPage"
              )}
            </Text>
          </Flex>

          <PhotoBookPage
            key={firstPageId}
            isActive={firstPageId === currentSelectedPageId}
            pageIndex={0}
            lineItemId={lineItemId}
            productPageId={firstPageId!}
            isMobile
          />

          {middlePageIds.map((id, index) => {
            return (
              <PhotoBookPage
                key={id}
                isActive={id === currentSelectedPageId}
                pageIndex={index + 1}
                lineItemId={lineItemId}
                productPageId={id}
                isMobile
              />
            )
          })}

          {canAddMorePages && (
            <GridItem colSpan={2}>
              <Flex
                w="full"
                borderWidth={1}
                borderColor="primary.50"
                borderRadius="md"
                height={100}
                alignItems="center"
                justifyContent="center"
                bg="gray.50"
              >
                <Button
                  colorScheme="primary"
                  size="sm"
                  onClick={onInsertPages}
                  id="photobook-add-spread"
                >
                  {t(
                    "components.editors.common.pagination.PhotoBookPageName.AddSpread"
                  )}
                </Button>
              </Flex>
            </GridItem>
          )}

          <PhotoBookPage
            key={lastPageId}
            isActive={lastPageId === currentSelectedPageId}
            pageIndex={pageIds.length - 1}
            lineItemId={lineItemId}
            productPageId={lastPageId!}
            isMobile
          />

          <Flex direction="column" align="center">
            <Box
              w="full"
              h="full"
              mb={1}
              borderWidth={2}
              borderColor="transparent"
              borderRightRadius="md"
              boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
            >
              <BlankPage pageHeight={height} pageWidth={width} isMobile />
            </Box>
            <Text>
              {t(
                "components.editors.common.pagination.PhotoBookMobilePageList.BlankPage"
              )}
            </Text>
          </Flex>
        </SimpleGrid>
      </SortableContext>
    </DndContext>
  )
}

export default PhotoBookMobilePageList
