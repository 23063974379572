import { Box, Flex, ScaleFade } from "@chakra-ui/react"
import React from "react"
import { useMeasure, useWindowSize } from "react-use"
interface Props {
  topbar?: React.ReactElement
  sidebar: React.ReactElement
  editor: React.ReactElement
  pageNavigator?: React.ReactElement
}

const EditorContainer: React.FC<Props> = ({
  topbar,
  sidebar,
  editor,
  pageNavigator,
}) => {
  const { height, width: windowWidth } = useWindowSize()
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const paginationWidth =
    windowWidth > 1000 + width ? "1000px" : `calc(100vw - ${width}px)`

  return (
    <ScaleFade initialScale={0.9} in={true}>
      <Flex
        id="desktop-editor"
        flex={1}
        height={height}
        width="100vw"
        backgroundColor="white"
      >
        <Flex boxShadow="lg" zIndex={1} minW={250} maxW={400} ref={ref}>
          <Box padding={3} w="full">
            {sidebar}
          </Box>
        </Flex>
        <Flex
          flex={1}
          direction="column"
          w={`calc(100vw - ${width}px)`}
          bg="blackAlpha.50"
        >
          {topbar && (
            <Box
              padding={0}
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              margin={2}
              alignSelf="center"
            >
              {topbar}
            </Box>
          )}
          <Flex flex={1} height="full">
            {editor}
          </Flex>
          {pageNavigator && (
            <Flex
              w={`calc(100vw - ${width}px)`}
              zIndex={0}
              alignContent="center"
              justifyContent="center"
            >
              <Flex direction="column" maxWidth={paginationWidth}>
                {pageNavigator}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </ScaleFade>
  )
}

export default EditorContainer
