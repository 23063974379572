import { EntityId } from "@jackfruit/common"
import React, { useCallback, useEffect } from "react"
import { useWindowSize } from "react-use"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useAllImageTransformsForLineItem } from "~/hooks/useAllImageTransformsForLineItem"
import { useAllTextRegionsForLineItem } from "~/hooks/useAllTextRegionsForLineItem"
import { useLineItem } from "~/hooks/useLineItem"
import { editorCurrentProductId, selectedRegion } from "./common/atoms"
import { currentSelectedPageIdFamily } from "./common/pagination/atoms"
import SyncReduxImageTransformToRecoil from "./common/watchers/SyncReduxImageTransformToRecoil"
import SyncReduxTextRegionToRecoil from "./common/watchers/SyncReduxTextRegionToRecoil"
import GiftCardEditor from "./giftcard/GiftCardEditor"
import PhotoBookEditor from "./photo-book/PhotoBookEditor"
import PrintEditor from "./print/PrintEditor"
import TemplateEditor from "./template/TemplateEditor"

export type ViewType = "mobile" | "mobile landscape" | "desktop"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
}
const Editor: React.FC<Props> = ({ lineItemId, onComplete }) => {
  const {
    product,
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)
  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )
  const { imageTransforms } = useAllImageTransformsForLineItem(lineItemId)
  const { textRegions } = useAllTextRegionsForLineItem(lineItemId)
  const setEditorCurrentProductId = useSetRecoilState(editorCurrentProductId)
  const setSelectedRegion = useSetRecoilState(selectedRegion)

  const isPhotoBook = product.categoryName === "photo-book"

  const handleOnComplete = useCallback(() => {
    // reset imageregion selection
    onComplete()
    setSelectedRegion({ id: 0 })
  }, [onComplete, setSelectedRegion])

  useEffect(() => {
    setEditorCurrentProductId(product.id)
  }, [product, setEditorCurrentProductId])

  // Use mobile landscape view if available height is less than this number
  const LANDSCAPE_HEIGHT = 500
  // Use mobile view if height or width is less than this number
  const MOBILE_HEIGHT = 768
  const MOBILE_WIDTH = 768

  const { width, height } = useWindowSize()

  let viewType: ViewType

  if (height < LANDSCAPE_HEIGHT) {
    viewType = "mobile landscape"
  } else if (height < MOBILE_HEIGHT || width < MOBILE_WIDTH) {
    // height > mobile height range (default is 768)
    viewType = "mobile"
  } else {
    viewType = "desktop"
  }

  let editorType

  if (product.productType === "template") {
    editorType = "template"
  }
  if (isPhotoBook) {
    editorType = "photoBook"
  }

  useEffect(() => {
    setCurrentSelectedPageId(currentSelectedPageId || productPageIds[0])
  }, [currentSelectedPageId, productPageIds, setCurrentSelectedPageId])

  if (!currentSelectedPageId) {
    return null
  }

  const imageTransformWatchers = (
    <>
      {imageTransforms.map(imageTransform => {
        return (
          <SyncReduxImageTransformToRecoil
            key={imageTransform.id}
            imageTransformId={imageTransform.id}
          />
        )
      })}
    </>
  )

  const textRegionWatchers =
    textRegions && textRegions.length > 0 ? (
      <>
        {textRegions.map(textRegion => {
          return (
            <SyncReduxTextRegionToRecoil
              key={textRegion.id}
              textRegionId={textRegion.id}
            />
          )
        })}
      </>
    ) : null

  if (product.categoryName === "gift certificate") {
    return (
      <GiftCardEditor
        lineItemId={lineItemId}
        onComplete={handleOnComplete}
        viewType={viewType}
      />
    )
  }

  switch (editorType) {
    case "template":
      return (
        <>
          {imageTransformWatchers}
          {textRegionWatchers}
          <TemplateEditor
            lineItemId={lineItemId}
            onComplete={handleOnComplete}
            viewType={viewType}
          />
        </>
      )
    case "photoBook":
      return (
        <>
          {imageTransformWatchers}
          <PhotoBookEditor
            lineItemId={lineItemId}
            onComplete={handleOnComplete}
            viewType={viewType}
          />
        </>
      )
    default:
      return (
        <>
          {imageTransformWatchers}
          <PrintEditor
            lineItemId={lineItemId}
            onComplete={handleOnComplete}
            viewType={viewType}
          />
        </>
      )
  }
}
export default Editor
