import { useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSetRecoilState } from "recoil"
import { useImageRegionActions } from "~/hooks/useImageRegion"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import { selectedRegion } from "../atoms"

interface ModalDialog {
  title: string
  description: string
  confirmLabel: string
}

export const useUpdatePhoto = ({
  lineItemId,
  imageRegionId,
}: {
  lineItemId: EntityId
  imageRegionId: EntityId
}) => {
  const { t } = useTranslation()
  const {
    product: { maxImages },
  } = useLineItem(lineItemId)

  const defaultDialog: ModalDialog = {
    title: t("components.editors.common.hooks.useUploadPhoto.title"),
    description: t(
      "components.editors.common.hooks.useUploadPhoto.description"
    ),
    confirmLabel: t(
      "components.editors.common.hooks.useUploadPhoto.warningConfirmLabel"
    ),
  }

  const [modalDialog, setModalDialog] = useState<ModalDialog>(defaultDialog)

  const {
    isOpen: isConfirmDialogOpen,
    onClose: closeConfirmDialog,
    onOpen: openConfirmDialog,
  } = useDisclosure()

  const { setUpdateMissingImagesDialogChoice } = useProcessActions()
  const { updateImage } = useImageRegionActions()
  const { updateMissingImages } = useLineItemActions()
  const setSelectedRegion = useSetRecoilState(selectedRegion)

  const enhancedOnOpen = useCallback(
    (type?: string) => {
      if (type === "warning") {
        setModalDialog({
          title: t(
            "components.editors.common.hooks.useUploadPhoto.warningTitle",
            { maxImages }
          ),
          description: t(
            "components.editors.common.hooks.useUploadPhoto.warningDescription",
            { maxImages }
          ),
          confirmLabel: t(
            "components.editors.common.hooks.useUploadPhoto.warningConfirmLabel"
          ),
        })
      }
      openConfirmDialog()
    },
    [maxImages, openConfirmDialog, t]
  )

  const onPhotoChange = useCallback(
    (files: File[]) => {
      if (files.length === 1) {
        updateImage(imageRegionId, files[0])
        setSelectedRegion({ type: "image", id: imageRegionId })
      } else {
        updateMissingImages(
          lineItemId,
          files,
          enhancedOnOpen,
          closeConfirmDialog
        )
      }
    },
    [
      updateImage,
      imageRegionId,
      setSelectedRegion,
      updateMissingImages,
      lineItemId,
      enhancedOnOpen,
      closeConfirmDialog,
    ]
  )

  const onConfirm = useCallback(() => {
    setUpdateMissingImagesDialogChoice({ choice: true })
  }, [setUpdateMissingImagesDialogChoice])

  const onCancel = useCallback(() => {
    setUpdateMissingImagesDialogChoice({ choice: false })
  }, [setUpdateMissingImagesDialogChoice])

  return {
    isConfirmDialogOpen,
    modalDialog,
    onPhotoChange,
    onConfirm,
    onCancel,
  }
}
