import { Box, Portal } from "@chakra-ui/react"
import React from "react"
import { RemoveScroll } from "react-remove-scroll"
import { useWindowSize } from "react-use"

interface Props {
  children: React.ReactNode
}

const FullScreen: React.FC<Props> = ({ children }) => {
  const { height } = useWindowSize()
  return (
    <Portal>
      <RemoveScroll>
        <Box position="fixed" height={height} zIndex={100} width="100%">
          {children}
        </Box>
      </RemoveScroll>
    </Portal>
  )
}

export default FullScreen
