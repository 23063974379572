import { Button, Flex, Icon, Text } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiChevronLeft, FiChevronRight, FiGrid } from "react-icons/fi"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { useMobilePageNavigatorDisclosure } from "./hooks/useMobilePageNavigatorDisclosure"
import { usePageEditorPagination } from "./hooks/usePageEditorPagination"
import { usePhotoBookNavigatorDisclosure } from "./hooks/usePhotoBookNavigatorDisclosure"

const pagerIconSize = 10

interface Props {
  lineItemId: EntityId
  children: React.ReactNode
}

const PageEditorPagerMobile: React.FC<Props> = ({ lineItemId, children }) => {
  const { t } = useTranslation()
  const { isPhotoBook } = usePhotoBook(lineItemId)
  const { onOpen } = useMobilePageNavigatorDisclosure()
  const { onOpen: onPhotoBookOpen } = usePhotoBookNavigatorDisclosure()
  const { hasPrevious, hasNext, handlePrevious, handleNext, currentPageLabel } =
    usePageEditorPagination(lineItemId)

  return (
    <>
      <Flex
        aria-label="PageEditorPagerMobile"
        width="full"
        alignItems="center"
        direction="row"
        height="full"
      >
        <Flex height="full" flex={0.12}>
          <Button
            id="previous-page-button"
            width="full"
            isDisabled={!hasPrevious}
            variant="link"
            leftIcon={<Icon as={FiChevronLeft} boxSize={pagerIconSize} />}
            onClick={handlePrevious}
          />
        </Flex>
        <Flex flex={1} height="full" direction="column">
          {children}
        </Flex>
        <Flex height="full" flex={0.12} justifyContent="flex-end">
          <Button
            id="next-page-button"
            width="full"
            isDisabled={!hasNext}
            variant="link"
            rightIcon={<Icon as={FiChevronRight} boxSize={pagerIconSize} />}
            onClick={handleNext}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="center">
        <Flex py={3} px={3} justifyContent="space-between" alignItems="center">
          <Button
            id="view-all-button"
            variant="unstyled"
            onClick={isPhotoBook ? onPhotoBookOpen : onOpen}
          >
            <Flex>
              <Icon as={FiGrid} boxSize={6} color="gray.600" mr={3} />
              <Text>
                {t(
                  "components.editors.common.pagination.PageEditorPagerMobile.ViewAllButton"
                )}
              </Text>
            </Flex>
          </Button>
          <Flex w={{ base: 20, sm: 36 }} />
          <Text>{currentPageLabel}</Text>
        </Flex>
      </Flex>
    </>
  )
}

export default PageEditorPagerMobile
