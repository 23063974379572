import {
  Button,
  Flex,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SelectProps,
  Text,
} from "@chakra-ui/react"
import { EntityId, PrintServiceProductEntityHydrated } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useProductPrices } from "~/hooks/useProductPrices"
import { formatCurrency } from "~/services/Utils"

interface ProductSelectOptionProps extends FlexProps {
  product?: PrintServiceProductEntityHydrated
}

const ProductSelectOption = ({
  product,
  ...props
}: ProductSelectOptionProps) => {
  const { defaultCurrency } = useApplicationState()
  const { productPrices } = useProductPrices(product?.prices ?? [])
  const productPrice = productPrices.find(
    price => price.currency === defaultCurrency
  )
  const showPrice = Boolean(
    productPrice && product?.metaData?.web?.displayPricing
  )

  return (
    <Flex
      justifyContent="space-between"
      fontWeight="semibold"
      color="gray.500"
      flex={1}
      {...props}
    >
      <Flex flex={1} overflow="hidden">
        <Text textOverflow="ellipsis" noOfLines={2}>
          {product?.shortDescription}
        </Text>
      </Flex>
      {showPrice && (
        <Flex ml={3}>
          <Text>
            {formatCurrency(productPrice.currency, productPrice.total)}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export interface Props extends SelectProps {
  currentProductId: EntityId
  onProductChange: (id: EntityId) => void
  products: PrintServiceProductEntityHydrated[]
}

const LineItemPreviewProductSelector: React.FC<Props> = ({
  currentProductId,
  onProductChange,
  products,
}) => {
  const { t } = useTranslation()

  const currentProduct = products.find(
    product => product.id === Number(currentProductId)
  )!

  const handleProductChange = useCallback(
    (id: EntityId) => {
      onProductChange(id)
    },
    [onProductChange]
  )

  return (
    <Menu matchWidth={true}>
      <MenuButton
        as={Flex}
        h="full"
        alignItems="center"
        w="full"
        px={2}
        borderRadius="md"
        data-testid={`select-menu-btn-${currentProduct?.id}`}
        data-remote-id={currentProduct?.remoteId}
        data-local-id={currentProduct?.id}
        _active={{ cursor: "pointer", bg: "gray.100" }}
        _hover={{ cursor: "pointer", bg: "gray.100" }}
      >
        <Flex justifyContent="space-between">
          <Flex flex={0.9}>
            <Text
              fontWeight="bold"
              textOverflow="ellipsis"
              noOfLines={2}
              data-testid={`product-name-${currentProduct?.id}`}
            >
              {currentProduct?.shortDescription}
            </Text>
          </Flex>
          <Button
            size="md"
            colorScheme="primary"
            variant="link"
            _hover={{ textDecoration: "none" }}
          >
            {t(
              "components.cart.line-item.LineItemPreviewProductSelector.MenuButton"
            )}
          </Button>
        </Flex>
      </MenuButton>
      <MenuList
        maxHeight="50vh"
        overflowY="scroll"
        zIndex={100}
        transform="none !important"
        data-testid="menu-list-products"
        w={"inherit"}
        overflowX="hidden"
      >
        {products.map(product => {
          const isSelected = product.id === currentProductId
          return (
            <MenuItem
              key={product.id}
              data-testid={product.id}
              data-remote-id={product.remoteId}
              data-local-id={product.id}
              bg={isSelected ? "gray.200" : "transparent"}
              onClick={() => handleProductChange(product.id)}
              w="inherit"
              overflow={"hidden"}
            >
              <ProductSelectOption product={product} />
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default LineItemPreviewProductSelector
