import {
  Flex,
  HStack,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { clamp } from "lodash"
import React, { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi"
import { useRecoilState, useRecoilValue } from "recoil"
import { useProduct } from "~/hooks/useProduct"
import {
  imageTransformationZoomStateFamily,
  editorCurrentProductId,
} from "./atoms"
import EditorButtonContainer from "./EditorButtonContainer"
import { useImage } from "~/hooks/useImage"
import { useUpload } from "~/hooks/useUpload"
import { useImageRegion } from "~/hooks/useImageRegion"
import { getDefaultImageTransformation } from "~/services/Utils"
interface Props {
  imageRegionId: EntityId
  imageTransformId: EntityId
  disabled: boolean
}

const EditorZoomSlider: React.FC<Props> = ({
  imageRegionId,
  imageTransformId,
  disabled,
}) => {
  const { t } = useTranslation()
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)

  const regionSize = useMemo(() => {
    return {
      width: imageRegion?.window.width || 0,
      height: imageRegion?.window.height || 0,
    }
  }, [imageRegion])

  const imageSize = useMemo(() => {
    return {
      width: image?.width || 0,
      height: image?.height || 0,
    }
  }, [image])

  const [zoom, setZoom] = useRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)

  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )
  const imageFit = product?.metaData?.web?.editor?.imageFit

  const zoomSetting = getDefaultImageTransformation(
    regionSize,
    imageSize,
    0,
    imageFit,
    isClampingEnabled
  )

  const zoomStep = zoomSetting.zoomStep * zoomSetting.zoom
  const maxZoom = zoomSetting.maxZoom
  const minZoom = zoomSetting.minZoom

  const onZoomUpPress = useCallback(() => {
    setZoom(clamp(zoom + zoomStep, minZoom, maxZoom))
  }, [setZoom, zoom, zoomStep, minZoom, maxZoom])

  const onZoomDownPress = useCallback(() => {
    setZoom(clamp(zoom - zoomStep, minZoom, maxZoom))
  }, [setZoom, zoom, zoomStep, minZoom, maxZoom])

  return (
    <EditorButtonContainer>
      <HStack spacing={3}>
        <IconButton
          id="scale-image-down"
          isDisabled={disabled}
          aria-label={t("components.editors.common.EditorZoomSlider.ScaleDown")}
          icon={<FiMinusCircle />}
          colorScheme="lightGray"
          variant="ghost"
          onClick={onZoomDownPress}
          borderRightRadius={0}
        />
        <Flex w="full" verticalAlign="center">
          <Slider
            className="scale-image-slider"
            isDisabled={disabled}
            colorScheme="primary"
            defaultValue={1}
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={zoomStep}
            onChange={setZoom}
            size="lg"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb borderWidth={2} borderColor="primary.500" />
          </Slider>
        </Flex>
        <IconButton
          id="scale-image-up"
          isDisabled={disabled}
          aria-label={t("components.editors.common.EditorZoomSlider.ScaleUp")}
          icon={<FiPlusCircle />}
          colorScheme="lightGray"
          variant="ghost"
          onClick={onZoomUpPress}
          borderLeftRadius={0}
        />
      </HStack>
    </EditorButtonContainer>
  )
}

export default EditorZoomSlider
