import { VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useLineItemFrame } from "~/hooks/useLineItemFrame"
import { ViewType } from "../Editor"
import Canvas from "./layers/Canvas"
import FoldedCard from "./layers/FoldedCard"
import Frame from "./layers/Frame"
import Overlay from "./layers/Overlay"
import Page from "./layers/Page"
import PhotoBook from "./layers/PhotoBook"

interface Props {
  lineItemId: EntityId
  productPageId: EntityId
  viewType: ViewType
}

const PageEditor: React.FC<Props> = ({
  viewType,
  lineItemId,
  productPageId,
}) => {
  const { product } = useLineItem(lineItemId)
  const { hasFrame, hasOverlay } = useLineItemFrame(lineItemId)!

  const { metaData } = product
  const isMobile = viewType === "mobile"
  const isMobileLandscape = viewType === "mobile landscape"

  const isACanvas = metaData?.wrap
  const isAFoldedCard = Boolean(product.metaData?.card?.folded)
  const isAPhotoBook = product.categoryName === "photo-book"
  const showPhotoBookEditor = isAPhotoBook && !isMobile && !isMobileLandscape

  const preventBrowserDefaultOnImageDrag = useCallback(e => {
    if (e.target.type === "textarea" || e.target instanceof SVGImageElement) {
      // allow clicking on image-regions
      return
    }
    e.preventDefault()
  }, [])

  const page = <Page lineItemId={lineItemId} productPageId={productPageId} />

  let component = null
  if (showPhotoBookEditor) {
    component = (
      <PhotoBook lineItemId={lineItemId} productPageId={productPageId} />
    )
  } else if (isAFoldedCard) {
    component = <FoldedCard lineItemId={lineItemId}>{page}</FoldedCard>
  } else if (isACanvas) {
    component = <Canvas lineItemId={lineItemId}>{page}</Canvas>
  } else if (hasFrame) {
    component = <Frame lineItemId={lineItemId}>{page}</Frame>
  } else if (hasOverlay) {
    component = <Overlay lineItemId={lineItemId}>{page}</Overlay>
  } else {
    component = page
  }

  return (
    <VStack h="full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        onMouseDown={preventBrowserDefaultOnImageDrag}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {component}
      </svg>
    </VStack>
  )
}

export default PageEditor
