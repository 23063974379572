/* eslint-disable mea/no-interactive-element-without-id-classname */
import { Box, Flex, HStack, VStack } from "@chakra-ui/react"
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable"
import { EntityId } from "@jackfruit/common"
import { last } from "lodash"
import React, { useCallback } from "react"
import { Element, scroller } from "react-scroll"
import { useEffectOnce, useUpdateEffect } from "react-use"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { photoBookPageIdsFamily } from "../atoms"
import { usePhotoBookDnd } from "../hooks/usePhotoBookDnd"
import Cover from "../photo-book/Cover"
import { currentSelectedPageIdFamily } from "./atoms"
import { usePhotoBookSpreads } from "./hooks/usePhotoBookSpreads"
import { boxHeight } from "./PageNavigationButton"
import PhotoBookAddSpreadButton from "./PhotoBookAddSpreadButton"
import PhotoBookAllPagesNavigation from "./PhotoBookAllPagesNavigation"
import PhotoBookPageName from "./PhotoBookPageName"
import PhotoBookPageNavigationButton, {
  PagePosition,
} from "./PhotoBookPageNavigationButton"

interface Props {
  lineItemId: EntityId
  productPageIds: EntityId[]
  width: number
}

const PhotoBookNavigationWrapper: React.FC<Props> = ({ lineItemId, width }) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 25 },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { distance: 25 },
  })

  const sensors = useSensors(mouseSensor, touchSensor)

  const {
    product: { maxImages },
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)
  const { hasCover, photoBookCoverThumbnail } = usePhotoBook(lineItemId)
  const { rearrangeProductPages } = usePhotoBookDnd(lineItemId)
  const [pageIds, setPageIds] = useRecoilState(
    photoBookPageIdsFamily(lineItemId)
  )
  const [currentSelectedPageId, setCurrentSelectedPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  useEffectOnce(() => {
    setPageIds(productPageIds)
  })

  const { setNodeRef } = useDroppable({
    id: `navigation-${lineItemId}`,
  })

  const isCoverActive = currentSelectedPageId === "cover"
  const { width: coverWidth, height: coverHeight } =
    photoBookCoverThumbnail?.metaData.photoBook!

  const ratio = coverWidth / coverHeight
  const boxWidth = boxHeight * ratio

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (active.id !== over?.id) {
        const oldIndex = pageIds.indexOf(active.id)
        const newIndex = pageIds.indexOf(over?.id!)
        const sortedPageIds = arrayMove(pageIds, oldIndex, newIndex)

        setPageIds(sortedPageIds)
        rearrangeProductPages(sortedPageIds)
      }
    },
    [pageIds, rearrangeProductPages, setPageIds]
  )

  useUpdateEffect(() => {
    setPageIds(productPageIds)
  }, [productPageIds])

  const paginated = usePhotoBookSpreads(lineItemId)
  const currentPageCount = productPageIds.length
  const canAddMorePages = currentPageCount < maxImages

  const onClick = (id: EntityId) => {
    setCurrentSelectedPageId(id)

    scroller.scrollTo(`page-button-${id}`, {
      containerId: "editorPaginationContainer",
      duration: 800,
      offset: -200,
      delay: 0,
      smooth: "easeInOutQuart",
      horizontal: true,
    })
  }

  return (
    <>
      <DndContext
        collisionDetection={closestCorners}
        onDragEnd={onDragEnd}
        sensors={sensors}
      >
        <SortableContext
          strategy={horizontalListSortingStrategy}
          items={[...pageIds]}
        >
          <Flex ref={setNodeRef}>
            {hasCover && (
              <Box ml={3} onClick={() => onClick("cover")}>
                <Element name={`page-button-cover`}>
                  <VStack
                    id={`page-button-cover`}
                    cursor="pointer"
                    borderRadius="md"
                  >
                    <Box
                      py={1}
                      mt={2}
                      bg="white"
                      borderWidth={2}
                      boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
                      borderRadius="md"
                      borderColor={
                        isCoverActive ? "primary.500" : "transparent"
                      }
                      _hover={{
                        borderColor: isCoverActive
                          ? "primary.500"
                          : "primary.100",
                      }}
                    >
                      <Cover
                        baseHeight={boxHeight}
                        height={coverHeight}
                        width={coverWidth}
                        image={photoBookCoverThumbnail!.thumbnail}
                      />
                    </Box>
                    <PhotoBookPageName
                      productPageIds={productPageIds}
                      w={`${boxWidth}px`}
                      type="cover"
                      align="center"
                      justify="center"
                    />
                  </VStack>
                </Element>
              </Box>
            )}

            {paginated.map((spread, index) => {
              const isFirst = index === 0
              const isLast = index === paginated.length - 1
              const isLastSpread = index === paginated.length - 2
              const pageIdToSelect = last(spread) || ""

              return (
                <HStack
                  ml={3}
                  mr={isLast ? 3 : 0}
                  spacing={0}
                  onClick={() => onClick(pageIdToSelect)}
                  key={pageIdToSelect}
                >
                  {isFirst && (
                    <PhotoBookPageNavigationButton
                      index={1}
                      lineItemId={lineItemId}
                      type="start"
                      position="left"
                    />
                  )}
                  {spread.map((pageId, spreadPosition) => {
                    let position: PagePosition =
                      spreadPosition === 0 ? "left" : "right"
                    if (isFirst) {
                      position = "right"
                    }
                    if (isLast) {
                      position = "left"
                    }

                    return (
                      <PhotoBookPageNavigationButton
                        key={pageId}
                        index={index}
                        position={position}
                        lineItemId={lineItemId}
                        productPageId={pageId}
                      />
                    )
                  })}

                  {Boolean(isLastSpread && canAddMorePages) && (
                    <PhotoBookAddSpreadButton lineItemId={lineItemId} />
                  )}
                  {isLast && (
                    <PhotoBookPageNavigationButton
                      index={0}
                      lineItemId={lineItemId}
                      type="end"
                      position="right"
                    />
                  )}
                </HStack>
              )
            })}
          </Flex>
        </SortableContext>
      </DndContext>
      <PhotoBookAllPagesNavigation width={width} lineItemId={lineItemId} />
    </>
  )
}

export default PhotoBookNavigationWrapper
