import { Box, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { isIOS } from "react-device-detect"
import { useRecoilCallback } from "recoil"
import { useHasImagesForAllImageRegions } from "~/hooks/useHasImagesForAllImageRegions"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { useProcessActions } from "~/hooks/useProcessActions"
import { scrollTo } from "~/services/Utils"
import EditorActionButtons from "../common/EditorActionButtons"
import {
  EditorCancelIncompleteConfirmation,
  EditorNoPhotoConfirmation,
} from "../print/PrintEditorSidebar"
import { EditorMissingPhotoOnPageCancelConfirmation } from "./PhotoBookEditorSidebar"

interface Props {
  lineItemId: EntityId
  onContinue: () => void
  onCancel: () => void
}

const PhotoBookEditorBottombarMobile: React.FC<Props> = ({
  lineItemId,
  onCancel,
  onContinue,
}) => {
  const {
    isOpen: isOpenMissingPictureModal,
    onOpen: onOpenMissingPictureModal,
    onClose: onCloseMissingPictureModal,
  } = useDisclosure()
  const {
    isOpen: isOpenCancelWithMissingImagesModal,
    onOpen: openCancelWithMissingImagesModal,
    onClose: closeCancelWithMissingImagesModal,
  } = useDisclosure()
  const {
    isOpen: isOpenCancelConfirmationModal,
    onOpen: onOpenCancelConfirmationModal,
    onClose: onCloseCancelConfirmationModal,
  } = useDisclosure()

  const { lineItem } = useLineItem(lineItemId)
  const isTemplate = lineItem.isTemplate

  const { nextBlockType } = useNextActionableBlock("template")
  const { remove, removeIncompletePageSpreads } = useLineItemActions()
  const { applyEditorChanges } = useProcessActions()
  const hasImagesForAllImageRegions = useHasImagesForAllImageRegions(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
    onContinue()
    isTemplate && scrollTo(nextBlockType)
    onCloseMissingPictureModal()
  })

  const onContinueEnhanced = useCallback(() => {
    if (!hasImagesForAllImageRegions) {
      onOpenMissingPictureModal()
      return
    }

    handleSaveChanges()
  }, [
    handleSaveChanges,
    hasImagesForAllImageRegions,
    onOpenMissingPictureModal,
  ])

  const onCancelEnhanced = useCallback(() => {
    const { isConfirmed } = lineItem

    if (!isConfirmed) {
      onOpenCancelConfirmationModal()
    } else if (!hasImagesForAllImageRegions) {
      openCancelWithMissingImagesModal()
    } else {
      onCancel()
    }
  }, [
    hasImagesForAllImageRegions,
    lineItem,
    onCancel,
    onOpenCancelConfirmationModal,
    openCancelWithMissingImagesModal,
  ])

  const handleRemoveItemAndClose = useCallback(() => {
    remove(lineItem.id)
    onCancel()
  }, [lineItem.id, onCancel, remove])

  const handleRemoveEmptyPagesAndClose = useCallback(() => {
    removeIncompletePageSpreads(lineItemId)
    onCancel()
  }, [lineItemId, onCancel, removeIncompletePageSpreads])

  return (
    <>
      <Box mt={4} mb={isIOS ? 10 : 0}>
        <EditorActionButtons
          size="lg"
          viewType="mobile"
          onContinue={onContinueEnhanced}
          onCancel={onCancelEnhanced}
        />
      </Box>

      <EditorNoPhotoConfirmation
        onClose={onCloseMissingPictureModal}
        onConfirm={onCloseMissingPictureModal}
        isOpen={isOpenMissingPictureModal}
        hasMultiplePages={hasMultiplePages}
      />

      <EditorCancelIncompleteConfirmation
        onClose={onCloseCancelConfirmationModal}
        onConfirm={handleRemoveItemAndClose}
        isOpen={isOpenCancelConfirmationModal}
      />

      <EditorMissingPhotoOnPageCancelConfirmation
        onClose={closeCancelWithMissingImagesModal}
        onConfirm={handleRemoveEmptyPagesAndClose}
        isOpen={isOpenCancelWithMissingImagesModal}
      />
    </>
  )
}

export default PhotoBookEditorBottombarMobile
