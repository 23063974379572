import { Box, BoxProps } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useDropzone } from "react-dropzone"
import ConfirmationModal from "~/components/ConfirmationModal"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import { useUpdatePhoto } from "./hooks/useUpdatePhoto"

interface Props extends BoxProps {
  lineItemId: EntityId
  imageRegionId: EntityId
}

const EditorChangeImageWrapper: React.FC<Props> = ({
  lineItemId,
  imageRegionId,
  children,
  ...boxProps
}) => {
  const {
    isConfirmDialogOpen,
    onPhotoChange,
    onConfirm,
    onCancel,
    modalDialog: { title, description, confirmLabel },
  } = useUpdatePhoto({ lineItemId, imageRegionId })

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onPhotoChange,
    noClick: true,
    noKeyboard: true,
    accept: UPLOAD_ACCEPTED_FILE_TYPES,
  })

  return (
    <Box {...getRootProps({ className: "dropzone" })} {...boxProps}>
      <input {...getInputProps()} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onClick: open })
        }
      })}
      <ConfirmationModal
        title={title}
        description={description}
        confirmLabel={confirmLabel}
        isOpen={isConfirmDialogOpen}
        onConfirm={onConfirm}
        onClose={onCancel}
        isCancelVisible={false}
        isCentered={true}
      />
    </Box>
  )
}

export default EditorChangeImageWrapper
