import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useLineItem } from "~/hooks/useLineItem"
import { useProductPage } from "~/hooks/useProductPage"
import { GA4AbandonmentRate } from "~/services/GA4"
import DesktopTopbar from "../common/DesktopTopbar"
import EditorSvgCropperMobileToolBar from "../common/EditorSvgCropperMobileToolBar"
import EditorSvgCropperMobileToolBarLandscape from "../common/EditorSvgCropperMobileToolBarLandscape"
import { useEditorStats } from "../common/hooks/useEditorStats"
import EditorContainer from "../common/layouts/EditorContainer"
import EditorContainerMobile from "../common/layouts/EditorContainerMobile"
import EditorContainerMobileLandscape from "../common/layouts/EditorContainerMobileLandscape"
import MultiplePagesEditor from "../common/MultiplePagesEditor"
import PageEditorMobilePageNavigation from "../common/pagination/PageEditorMobilePageNavigation"
import PageEditorPageNavigation from "../common/pagination/PageEditorPageNavigation"
import { ViewType } from "../Editor"
import { DesktopToolBarExperiment } from "../print/PrintEditor"
import PrintEditorBottombarMobile from "../print/PrintEditorBottombarMobile"
import PrintEditorTopbarMobile from "../print/PrintEditorTopbarMobile"
import TemplateEditorSidebar from "./TemplateEditorSidebar"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
  viewType: ViewType
}

const TemplateEditor: React.FC<Props> = ({
  lineItemId,
  onComplete,
  viewType,
}) => {
  const { lineItem } = useLineItem(lineItemId)
  const {
    filledProductPagesCount,
    fulfillment,
    printServiceName,
    productCategoryName,
    productName,
  } = useEditorStats(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1
  const { productPage } = useProductPage(lineItem.productPageIds[0])

  const onContinue = useCallback(() => {
    onComplete()
  }, [onComplete])
  const onCancel = useCallback(() => {
    onComplete()
    GA4AbandonmentRate({
      filledProductPagesCount,
      fulfillment,
      printServiceName,
      productName,
      productCategoryName,
    })
  }, [
    filledProductPagesCount,
    fulfillment,
    onComplete,
    printServiceName,
    productCategoryName,
    productName,
  ])

  const showToolbar =
    productPage!.imageRegionIds.length + productPage!.textRegionIds.length > 0

  const desktopToolBarExperiment = DesktopToolBarExperiment.showInSidebar
  // useGoogleOptimize(settings.googleOptimizeExperimentIdSix, [
  //   DesktopToolBarExperiment.showInSidebar,
  //   DesktopToolBarExperiment.showInTopbar,
  // ]) ?? DesktopToolBarExperiment.showInSidebar

  if (viewType === "mobile") {
    return (
      <EditorContainerMobile
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={
          showToolbar ? (
            <EditorSvgCropperMobileToolBar lineItemId={lineItemId} />
          ) : undefined
        }
        bottombar={
          <PrintEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          hasMultiplePages ? (
            <PageEditorMobilePageNavigation lineItemId={lineItemId} />
          ) : undefined
        }
      />
    )
  }

  if (viewType === "mobile landscape") {
    return (
      <EditorContainerMobileLandscape
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={
          showToolbar ? (
            <EditorSvgCropperMobileToolBarLandscape lineItemId={lineItemId} />
          ) : null
        }
        bottombar={
          <PrintEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          hasMultiplePages ? (
            <PageEditorMobilePageNavigation lineItemId={lineItemId} />
          ) : undefined
        }
      />
    )
  }

  return (
    <EditorContainer
      topbar={<DesktopTopbar lineItemId={lineItemId} />}
      sidebar={
        <TemplateEditorSidebar
          lineItemId={lineItemId}
          onContinue={onContinue}
          onCancel={onCancel}
          showTools={
            desktopToolBarExperiment === DesktopToolBarExperiment.showInSidebar
          }
        />
      }
      editor={
        <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
      }
      pageNavigator={
        hasMultiplePages ? (
          <PageEditorPageNavigation lineItemId={lineItemId} />
        ) : undefined
      }
    />
  )
}

export default TemplateEditor
