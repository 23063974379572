import { Box, useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useEffectOnce } from "react-use"
import { useRecoilValue } from "recoil"
import ConfirmationModal from "~/components/ConfirmationModal"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import { useLineItem } from "~/hooks/useLineItem"
import { GA4AbandonmentRate } from "~/services/GA4"
import { selectedRegion } from "../common/atoms"
import DesktopTopbar from "../common/DesktopTopbar"
import EditorSvgCropperMobileToolBar from "../common/EditorSvgCropperMobileToolBar"
import EditorSvgCropperMobileToolBarLandscape from "../common/EditorSvgCropperMobileToolBarLandscape"
import { useEditorStats } from "../common/hooks/useEditorStats"
import { useUpdatePhoto } from "../common/hooks/useUpdatePhoto"
import EditorContainer from "../common/layouts/EditorContainer"
import EditorContainerMobile from "../common/layouts/EditorContainerMobile"
import EditorContainerMobileLandscape from "../common/layouts/EditorContainerMobileLandscape"
import MultiplePagesEditor from "../common/MultiplePagesEditor"
import PhotoBookEditorMobilePageNavigation from "../common/pagination/PhotoBookEditorMobilePageNavigation"
import PhotoBookEditorPageNavigation from "../common/pagination/PhotoBookEditorPageNavigation"
import PromptModal from "../common/PromptModal"
import { ViewType } from "../Editor"
import PrintEditorTopbarMobile from "../print/PrintEditorTopbarMobile"
import PhotoBookEditorBottombarMobile from "./PhotoBookEditorBottombarMobile"
import PhotoBookEditorSideBar from "./PhotoBookEditorSidebar"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
  viewType: ViewType
}

const PhotoBookEditorSelector: React.FC<Props> = ({
  lineItemId,
  onComplete,
  viewType,
}) => {
  const {
    filledProductPagesCount,
    fulfillment,
    printServiceName,
    productCategoryName,
    productName,
  } = useEditorStats(lineItemId)

  const onContinue = useCallback(() => {
    onComplete()
  }, [onComplete])

  const onCancel = useCallback(() => {
    onComplete()
    GA4AbandonmentRate({
      filledProductPagesCount,
      fulfillment,
      printServiceName,
      productName,
      productCategoryName,
    })
  }, [
    onComplete,
    filledProductPagesCount,
    fulfillment,
    printServiceName,
    productName,
    productCategoryName,
  ])

  if (viewType === "mobile") {
    return (
      <EditorContainerMobile
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={<EditorSvgCropperMobileToolBar lineItemId={lineItemId} />}
        bottombar={
          <PhotoBookEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          <PhotoBookEditorMobilePageNavigation lineItemId={lineItemId} />
        }
      />
    )
  }

  if (viewType === "mobile landscape") {
    return (
      <EditorContainerMobileLandscape
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={
          <EditorSvgCropperMobileToolBarLandscape lineItemId={lineItemId} />
        }
        bottombar={
          <PhotoBookEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          <PhotoBookEditorMobilePageNavigation lineItemId={lineItemId} />
        }
      />
    )
  }

  //Desktop view
  return (
    <EditorContainer
      topbar={<DesktopTopbar lineItemId={lineItemId} />}
      sidebar={
        <PhotoBookEditorSideBar
          lineItemId={lineItemId}
          onContinue={onContinue}
          onCancel={onCancel}
          showTools
        />
      }
      editor={
        <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
      }
      pageNavigator={<PhotoBookEditorPageNavigation lineItemId={lineItemId} />}
    />
  )
}

const PhotoBookEditor: React.FC<Props> = props => {
  const { lineItemId } = props

  const {
    lineItem: { isConfirmed },
    product: { minImages, maxImages },
  } = useLineItem(lineItemId)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { id: imageRegionId } = useRecoilValue(selectedRegion)

  const {
    isConfirmDialogOpen,
    onConfirm,
    onCancel,
    onPhotoChange,
    modalDialog: { title, description, confirmLabel },
  } = useUpdatePhoto({
    lineItemId,
    imageRegionId,
  })

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onPhotoChange,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    accept: UPLOAD_ACCEPTED_FILE_TYPES,
  })

  useEffectOnce(() => {
    if (!isConfirmed) {
      onOpen()
    }
  })

  return (
    <>
      <PhotoBookEditorSelector {...props} />
      {!isConfirmed && (
        <Box {...getRootProps({ className: "dropzone" })}>
          <PromptModal
            maxImages={maxImages}
            minImages={minImages}
            isOpen={isOpen}
            onClose={onClose}
            openFileDialog={open}
          />
          <ConfirmationModal
            title={title}
            description={description}
            confirmLabel={confirmLabel}
            isOpen={isConfirmDialogOpen}
            onConfirm={onConfirm}
            onClose={onCancel}
            isCancelVisible={false}
            isCentered={true}
          />
          <input {...getInputProps()} />
        </Box>
      )}
    </>
  )
}

export default PhotoBookEditor
